import './index.css';
import React, { useEffect } from 'react';
import IPage from '../../interfaces/page';
import logging from '../../config/logging';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { selectConfig } from '../../app/configSlice';
import { setConfig } from '../../utils/setConfig';
import { Navigate, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { LoginType } from '../../api-types/models/LoginDto';

const LoginPage: React.FunctionComponent<IPage & { loadConfig?: boolean }> = (
    props
) => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const config = useAppSelector(selectConfig);
    const loginType = localStorage.getItem('login');

    useEffect(() => {
        logging.info(`Loading ${props.name}`);

        if (config) {
            setConfig(LoginType.SPORTRADAR, config);
        }
        instance.ssoSilent(loginRequest).catch((e) => {
            console.log('error SSO Silent', e);
        });
    }, [props.name]);

    if (loginType === LoginType.SPORTRADAR && isAuthenticated) {
        return <Navigate to='/' />;
    }

    async function signIn() {
        try {
            if (config) {
                setConfig(LoginType.SPORTRADAR, config);

                instance.loginRedirect(loginRequest).catch((e) => {
                    console.log(e);
                });
            }
        } catch (e: unknown) {
            console.log(e);
            alert((e as { message: string }).message);
        }
    }

    return (
        <div className='login-box'>
            <div className='login-wrapper'>
                <div className='logo'>
                    <img src='/images/ico_sportradar.svg' alt='logo' />
                </div>

                <div className='login-button' onClick={() => signIn()}>
                    <div>{t('login_button')}</div>
                </div>
                <p
                    className='manual-login'
                    onClick={() => navigate('/external')}
                >
                    {t('manual_login')}
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
